<template>
  <div class="order-container main-container">
    <van-nav-bar
      :title="$t('appointment.listTitle')"
      @click-right="toApply"
    >
      <template #right>
        <span class="order-plus">
          <svg-icon icon-class="plus"></svg-icon>
          {{$t('order.add')}}
        </span>
      </template>
    </van-nav-bar>
    <van-tabs @click="onClick" sticky v-model="activeTab">
      <van-tab v-for="(item, index) in tabs" :name="index + 1" :key="index" :title="item.name"></van-tab>
    </van-tabs>
    <div class="order-list">
      <van-empty :description="$t('empty')" v-if="!list.length"></van-empty>
      <div v-else class="order-item van-hairline--bottom" v-for="item in list" :key="item.room_id" @click="toDetail(item.room_id)">
        <svg-icon icon-class="item" class="order-item-icon" />
        <div class="order-info">
          <div class="order-info-hd">
            <div class="info-title">{{ item.title }}</div>
          </div>
          <div class="order-info-tip">
            <span class="info-time">{{$t('order.applyDate')}}：{{ item.created_time }}</span>
          </div>
        </div>
        <span class="item-right"></span>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
import order from '@/api/room'
import { mapState } from 'vuex'
const listQuery = {
  page: 1,
  pageSize: 20
}
export default {
  data() {
    return {
      listQuery: { ...listQuery },
      total: 0,
      list: [],
      activeTab: 1,
      tabs: [
        { params: {}, name: this.$t('order.all') },
        { name: this.$t('order.done'), params: { status: 70 } },
        { name: this.$t('order.cancel'), params: { status: 99 } },
      ]
    }
  },
  computed: {
    ...mapState(['locale'])
  },
  created() {
    this.fetchData()
  },
  methods: {
    toDetail(uuid) {
      this.$router.push(`/appointment/${uuid}`)
    },
    fetchData(page = 1) {
      this.listQuery.page = page
      const cb = this.$toast.loading({
        duration: 0,
        forbidClick: true,
        message: this.$t('loading')
      })
      order.getList(this.listQuery)
      .then(res => {
        cb.close()
        this.total = res.total || 0
        this.list = res.list || []
      })
    },
    onClick(name) {
      const tab = this.tabs.find((v, index) => name === index + 1)
      this.listQuery = {
        ...listQuery,
        ...tab.params
      }
      this.fetchData()
    },
    toApply() {
      this.$router.push(`/appointment`)
    },
    getTitle(item) {
      const count = item.orderCosts.length
      const suffix = count > 1 ? (this.locale === 'en-US' ? `（${count} pieces）` : `（共${count}件）`) : ''
      return count > 0 ? (this.locale === 'en-US' ? item.orderCosts[0].cost_name_en : item.orderCosts[0].cost_name) + suffix : ''
    },
    getStatus(item) {
      if (item.review_status !== 2) {
        return item.review_status_name
      }
      if (item.pay_status !== 1) {
        return item.pay_status_name
      }
      if (item.logistics_status !== 1) {
        return item.logistics_status_name
      }
      return item.status_name
    },
  }
}
</script>

<style lang="less" scoped>
.order {
  &-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    overflow: hidden;
  }
  &-list {
    flex: 1;
    overflow-y: auto;
  }
  &-item {
    display: flex;
    color: #333;
    padding: 15px;
    &-icon {
      font-size: 22px;
    }
    .item-right {
      width: 0;
      height: 0;
      border: 4px solid transparent;
      border-left-color: #333;
      margin-top: 20px;
    }
  }
  &-info {
    flex: 1;
    margin: 0 15px;
    &-hd {
      display: flex;
      align-items: center;
    }
    .info-title {
      line-height: 20px;
      font-size: 14px;
      flex: 1;
    }
    &-tip {
      font-size: 13px;
      line-height: 18px;
      color: #666;
      margin-top: 10px;
    }
    .info-fee {
      margin-right: 10px;
    }
  }
  &-plus {
    color: @orange;
    font-weight: bold;
    font-size: 16px;
  }
}
</style>
